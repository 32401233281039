<template>
  <div class="policy-detail">
    <div class="admin-table-search" style="padding-top: 10px">
      <el-form
        ref="queryForm"
        :model="tableQuery"
        label-position="right"
        size="medium"
        @submit.native.prevent="search"
        class="admin-tablequery">
        <div class="form-div">
          <span v-if="$props.row.compensateAuditStatus == 1"
            >提交总数：{{ tableData.total }}单，确认赔付：{{ $props.row.compensateNum }}单，</span
          >
          <span v-else>提交总数：{{ tableData.total }}单，</span>
          结算金额{{ $props.row.compensateTotalAmount }}元
        </div>
        <el-form-item style="margin-left: auto; text-align: right">
          <el-button size="medium" type="primary" :loading="tableLoading" native-type="submit" class="admin-btn">
            查询
          </el-button>
          <el-button size="medium" type="primary" @click="exportHandler()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="admin-table-list">
      <el-table :data="tableData.data" border @sort-change="tableSortChangeHandler">
        <el-table-column label="会员服务单号" prop="compensateApplyNo"></el-table-column>
        <el-table-column label="流水号" prop="compensateAuditNo"></el-table-column>
        <el-table-column label="姓名" prop="drvStudentName"></el-table-column>
        <el-table-column label="身份证号" prop="drvStudentIdentityCard"></el-table-column>
        <el-table-column
          label="补偿科目"
          prop="compensateSub"
          width="100"
          :formatter="$formatter.dict($dict.examSub)"></el-table-column>
        <el-table-column label="补偿凭证" prop="compensateReceipt">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="$dict.SETTING.API_URL + '/image/' + scope.row.compensateReceipt"
              :preview-src-list="[$dict.SETTING.API_URL + '/image/' + scope.row.compensateReceipt]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="结算金额" prop="compensateAmount"></el-table-column>
        <el-table-column
          label="创建时间"
          prop="creationTime"
          :formatter="$formatter.date(true)"
          sortable="custom"></el-table-column>
        <el-table-column label="审核状态" prop="compensateStatus" v-if="$props.row.compensateAuditStatus == 1">
          <template slot-scope="scope">
            <span v-if="scope.row.compensateStatus == 1" style="color: #34b377">符合</span>
            <span v-else-if="scope.row.compensateStatus == 2" style="color: #ff422d">
              不符合
              <el-tooltip class="item" effect="light" :content="scope.row.auditReasons" placement="top">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>

    <div class="admin-dialog-btns">
      <el-button @click="$emit('end')">关闭</el-button>
    </div>
  </div>
</template>

<script>
import {schReconciliationGetDetails, schReconciliationExportGetList} from '@/api/index';
import tableMixin from '@/mixin/table-page';
export default {
  mixins: [tableMixin(schReconciliationGetDetails, true)],
  props: {
    row: Object,
  },
  data() {
    return {
      tableLoading: false,
      tableQuery: {
        drvCompensateAuditId: this.$props.row.drvCompensateAuditId, // 姓名
        templateType: '', // 统筹类型 1补考费统筹
        creationTimeOrder: '', // 时间排序 1倒叙 2正序
      },
      tableData: {
        total: 0,
        data: [],
      },
    };
  },
  methods: {
    tableSortChangeHandler(e) {
      if (e.order == 'ascending') {
        // 升序
        this.tableQuery.creationTimeOrder = 2;
      } else if (e.order == 'descending') {
        // 倒序
        this.tableQuery.creationTimeOrder = 1;
      } else {
        this.tableQuery.creationTimeOrder = '';
      }
      this.search();
    },

    exportHandler() {
      schReconciliationExportGetList(this.tableQuery).then((res) => {
        if (res.status == 200) {
          this.$utils.downloadFile(res);
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      return data;
    },
  },
};
</script>

<style lang="scss">
.policy-detail {
  .form-div {
    height: 38px;
    margin-right: 16px;
    font-size: 16px;
  }
}
</style>